import {io} from "socket.io-client";
import {env} from "@/configs";
import store from "@/store";

export const testsModule = {
  namespaced: true,
  state: () => ({
    testsState: null,
    settings: null,
    socket: null,
  }),
  mutations: {
    setSocket(state: any, value: any): void {
      state.socket = value;
    },
    setState(state: any, value: any): void {
      state.testsState = value;
    },
    setSettings(state: any, value: any): void {
      state.settings = value;
    },
  },
  actions: {
    async setup({commit}:any) {
      console.log(`${env.testsUrl}/rtp-tests`);
      const socket = io(`${env.testsUrl}/rtp-tests`);
      socket.on('state', (state) => {
        console.log('state', state);
        commit('setState', state);
      })
      socket.on('settings', (settings) => {
        console.log('settings', settings);
        commit('setSettings', settings);
      })
      socket.on('error', (error) => {
        commit('setError', JSON.stringify(error), { root: true })
      })
      commit('setSocket', socket);
    },
    setSetting({state}: any, data:any): void {
      const prop = data[0];
      const value = data[1];
      console.log('here emit');
      state.socket.emit('settings', {
        ...state.settings,
        [prop]: value,
      })
    },
  },
  getters: {
    ready: (state:any) => !!state.socket && !!state.settings,
    state: (state:any) => state.testsState,
  },
};
