export const baseModule = {
  state: () => ({
    templateDomain: '',
    promoVersion: '',
    slotCore: ''
  }),
  getters: {
    templateDomain(state: any): string {
      return state.templateDomain;
    },
    promoVersion(state: any): string {
      return state.promoVersion;
    },
    slotCore(state: any): string {
      return state.slotCore;
    }
  },
  mutations: {
    templateDomain(state: any, value: any): void {
      state.templateDomain = value;
    },
    promoVersion(state: any, value: any): void {
      state.promoVersion = value;
    },
    slotCore(state: any, value: any): void {
      state.slotCore = value;
    }
  }
};
