import Api, { ApiInstance } from '@/utils/api';


import getEnv from './utils/env';

export const boot = (): void => {
    Api.boot(ApiInstance.Autotests, {
        baseURL: getEnv('VUE_APP_API_URL'),
        timeout: 100000
    });
    Api.boot(ApiInstance.Generator, {
        baseURL: getEnv('VUE_APP_GENERATOR_API_URL'),
        timeout: 3000
    });
};
