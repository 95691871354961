var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c(_vm.layout, {
    tag: "component",
    staticClass: "mt-2"
  }, [_c('vue-extend-layouts')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }