import Api, { ApiInstance } from '@/utils/api';

export const scenariosModule = {
    state: () => ({
        scenarios: [],
        selectedScenario: []
    }),
    getters: {
        scenarios(state: any): any[] {
            return state.scenarios;
        },
        selectedScenario(state: any): any[] {
            return state.selectedScenario;
        }
    },
    mutations: {
        selectedScenario(state: any, value: any): void {
            state.selectedScenario = value;
        },
        scenarios(state: any, value: any[]): void {
            state.scenarios = value;
        }
    },
    actions: {
        async scenarios({ commit, getters }: any) {
            const selectedScenario = getters.selectedScenario;
            const scenarios = await Api.instance(ApiInstance.Autotests).get('tests/scenarios');

            commit('scenarios', scenarios);
            commit(
                'selectedScenario',
                scenarios.find((scenario: any) => scenario?.name === selectedScenario?.name) ?? scenarios[0]
            );
        },
        async createTest({ dispatch }: any, options: { scenarioName: string; test: Test }) {
            const { queryName, name, queryBody, priority, result, onRoundState } = options.test;

            await Api.instance(ApiInstance.Autotests).put('tests/query', {
                scenarioName: options.scenarioName,
                queryName,
                priority: parseInt(priority),
                name,
                queryBody,
                onRoundState,
                result
            });
            await dispatch('scenarios');
        }
    }
};

export interface Test {
    queryName: string;
    priority: string;
    name: string;
    queryBody: string;
    result: 'success' | 'failure';
    onRoundState: string;
}
