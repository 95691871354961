var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    staticClass: "ma-3",
    attrs: {
      "right": "",
      "max-width": "700"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function (_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "color": "grey",
            "text": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openDialog(_vm.$store.getters.error);
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" More ")]), _c('v-btn', _vm._b({
          attrs: {
            "color": "red",
            "text": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              _vm.snackbarVisibility = false;
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" Close ")])];
      }
    }]),
    model: {
      value: _vm.snackbarVisibility,
      callback: function ($$v) {
        _vm.snackbarVisibility = $$v;
      },
      expression: "snackbarVisibility"
    }
  }, [_c('v-dialog', {
    attrs: {
      "max-width": "700"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h6"
  }, [_vm._v(" Login ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-3 overflow-hidden"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey",
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("Close")])], 1)], 1)], 1), _vm._v(" " + _vm._s(_vm.$store.getters.error.message) + " ")], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }