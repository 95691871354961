import { scenariosModule } from '@/store/modules/scenarios';
import { errorsModule } from '@/store/modules/errors';
import { baseModule } from '@/store/modules/base';
import Vuex, { StoreOptions } from 'vuex';
import Vue from 'vue';
import {infoModule} from "@/store/modules/info";
import {testsModule} from "@/store/modules/tests";

Vue.use(Vuex);

const options: StoreOptions<{ error: any }> = {
  modules: {
    scenarios: scenariosModule,
    errors: errorsModule,
    base: baseModule,
    info: infoModule,
    tests: testsModule,
  }
};

export default new Vuex.Store(options) as any;
