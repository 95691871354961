import { ApiError } from '@/utils/error';
import axios, { type AxiosInstance, AxiosRequestConfig } from 'axios';
import store from '@/store';

enum METHOD {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete'
}

const HEADERS = {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*'
};

type Headers = Record<string, string>;
export default class Api {
    private static apis: Record<ApiInstance, Api> = {} as any;
    private readonly axios: AxiosInstance;

    constructor(config?: AxiosRequestConfig) {
        this.axios = axios.create(config);
    }

    static boot(name: ApiInstance, config?: AxiosRequestConfig): void {
        this.apis[name] = new this({ ...config, headers: HEADERS });
    }

    static instance(name: ApiInstance, config?: AxiosRequestConfig): Api {
        return (this.apis[name] ??= new this({ ...config, headers: HEADERS }));
    }

    public async get(url: string, params?: unknown, headers: Headers = {}, ignoreError = false): Promise<any> {
        return this._send({ method: METHOD.GET, headers, url, params }, ignoreError);
    }

    public async post(url: string, data?: unknown, headers: Headers = {}, ignoreError = false): Promise<any> {
        return this._send({ method: METHOD.POST, headers, url, data }, ignoreError);
    }

    public async put(url: string, data?: unknown, headers: Headers = {}, ignoreError = false): Promise<any> {
        return this._send({ method: METHOD.PUT, headers, url, data }, ignoreError);
    }

    public async delete(url: string, data: unknown = {}, headers: Headers = {}, ignoreError = false): Promise<any> {
        return this._send({ method: METHOD.DELETE, headers, url, data }, ignoreError);
    }

    private async _send(
        request: { method: METHOD; url: string; headers: Record<string, string>; data?: unknown; params?: unknown },
        ignoreError = false
    ): Promise<unknown> {
        request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwt-token');
        let response: any;
        try {
            response = await this.axios(request).then(({ data }) => data);
            // @ts-ignore
        } catch (e: AxiosError) {
            if (ignoreError) return;
            const message = e.response
                ? `Code: ${e.response.status} | ${e.response.data.message ?? e.response.message}`
                : `Request: ${request.method.toUpperCase()} | ${request.url}`;

            const response = { message, request, response: e.response ?? null };
            store.commit('setError', JSON.stringify(response));

            throw new ApiError('Api error', response);
        }
        return response;
    }

    private checkStatus(response: any) {
        if (response?.status >= 400) {
            store.commit('setError', response?.message);
        }
        return response;
    }

    static get autotests(): Api {
        return this.instance(ApiInstance.Autotests);
    }

    static get generator(): Api {
        return this.instance(ApiInstance.Generator);
    }
}

export enum ApiInstance {
    Autotests = 'autotests',
    Generator = 'generator'
}
