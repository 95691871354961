<template>
    <v-btn @click="exit" class="ma-2" icon>
        <v-icon>mdi-exit-run</v-icon>
    </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'Logout',
    methods: {
        exit(): void {
            localStorage.setItem('jwt-token', '');
            this.$router.push({ name: 'login' });
        }
    }
});
</script>

<style scoped></style>
