export const errorsModule = {
  state: () => ({
    error: false
  }),
  getters: {
    error(state: any): any {
      return JSON.parse(state.error);
    },
    hasError(state: any): boolean {
      return !!state.error;
    }
  },
  mutations: {
    clearError(state: any, value: boolean): void {
      state.error = value;
    },
    setError(state: any, message: any): void {
      state.error = message;
    }
  }
};
