export class GeneralError extends Error {
  private readonly _extra: any;

  constructor(message: string, extra?: any) {
    super(message);
    this._extra = extra;
  }

  public get extra() {
    return this._extra;
  }
}

export class ApiError extends GeneralError {}
