<template>
    <v-img height="100%" src="/bg.jpg">
        <v-container>
            <router-view></router-view>
            <ErrorDialog />
        </v-container>
    </v-img>
</template>

<script>
import ErrorDialog from '@/components/ErrorDialog';
export default {
    name: 'empty-layout',
    components: { ErrorDialog }
};
</script>

<style scoped></style>
