var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-img', {
    attrs: {
      "height": "100%",
      "src": "/bg.jpg"
    }
  }, [_c('v-container', [_c('router-view'), _c('ErrorDialog')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }