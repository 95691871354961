<template>
    <v-snackbar right class="ma-3" max-width="700" v-model="snackbarVisibility">
        <template v-slot:action="{ attrs }">
            <v-btn color="grey" text v-bind="attrs" @click="openDialog($store.getters.error)" small> More </v-btn>
            <v-btn color="red" text v-bind="attrs" @click="snackbarVisibility = false" small> Close </v-btn>
        </template>
        <v-dialog v-model="dialog" max-width="700">
            <v-card>
                <v-card-title class="text-h6"> Login </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-3 overflow-hidden">
                    {{ message }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="closeDialog">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        {{ $store.getters.error.message }}
    </v-snackbar>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'ErrorDialog',
    data() {
        return {
            dialog: false,
            message: ''
        };
    },
    computed: {
        snackbarVisibility: {
            get(): boolean {
                return this.$store.getters.hasError;
            },
            set(val) {
                this.$store.commit('clearError', val);
            }
        }
    },
    methods: {
        openDialog(message: any): void {
            delete message.request?.headers;
            delete message?.response?.headers;
            delete message?.response?.config;
            this.message = message;

            this.snackbarVisibility = false;
            this.dialog = true;
        },
        closeDialog(): void {
            this.message = '';
            this.dialog = false;
        }
    }
});
</script>
