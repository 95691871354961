import { RouteConfig } from 'vue-router';

export enum ROUTE_NAMES {
    LOGIN = 'login',
    REG = 'registration',
    DASHBOARD = 'dashboard',
    TESTS = 'tests',
    RTP_TESTS = 'rtp_tests',
    RTP_REPORTS = 'rtp_reports',
    LOGS = 'logs',
    HACKING_TOOLS = 'hacking-tools',
    SETTINGS = 'settings',
    PLAY = 'play',
    WORKSHOP = 'workshop',
    CRASH_BOTS = 'crash-bots'
}

const meta = {
    layout: 'main-layout'
};

export const routes: Array<RouteConfig> = [
    {
        path: '*',
        redirect: '/play'
    },
    {
        path: '/login',
        name: ROUTE_NAMES.LOGIN,
        component: () => import('@/views/LoginView.vue')
    },
    {
        path: '/dashboard',
        name: ROUTE_NAMES.DASHBOARD,
        component: () => import('@/views/MainPage.vue'),
        meta
    },
    {
        path: '/tests',
        name: ROUTE_NAMES.TESTS,
        component: () => import('@/views/TestPage.vue'),
        meta
    },
    {
        path: '/rtp_tests',
    name: ROUTE_NAMES.RTP_TESTS,
    component: () => import('@/views/RtpTestPage.vue'),
    meta
  },
  {
    path: '/rtp_reports',
    name: ROUTE_NAMES.RTP_REPORTS,
    component: () => import('@/views/RtpReports.vue'),
    meta
  },
  {
    path: '/hacking-tools',
        name: ROUTE_NAMES.HACKING_TOOLS,
        component: () => import('@/views/HackingToolsPage.vue'),
        meta
    },
    {
        path: '/play',
        name: ROUTE_NAMES.PLAY,
        component: () => import('@/views/PlayPage.vue'),
        meta
    },
    {
        path: '/settings',
        name: ROUTE_NAMES.SETTINGS,
        component: () => import('@/views/SettingsPage.vue'),
        meta
    },
    {
        path: '/logs',
        name: ROUTE_NAMES.LOGS,
        component: () => import('@/views/Logs.vue'),
        meta
    },
    {
        path: '/workshop',
        name: ROUTE_NAMES.WORKSHOP,
        component: () => import('@/views/WorkShop.vue'),
        meta
    },
    {
        path: '/crash-bots',
        name: ROUTE_NAMES.CRASH_BOTS,
        component: () => import('@/views/CrashBots.vue'),
        meta
    }
];
