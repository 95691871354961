import Api, { ApiInstance } from '@/utils/api';

export const getWsConnection = async (): Promise<any> => {
    return Api.instance(ApiInstance.Autotests).get('/info/env/ws');
};

export const env = {
    apiUrl: process.env.VUE_APP_API_URL,
    generatorUrl: process.env.VUE_APP_GENERATOR_API_URL,
    getBotsTimeout: process.env.VUE_APP_GET_BOTS_TIMEOUT ? +process.env.VUE_APP_GET_BOTS_TIMEOUT : undefined,
    dateFormat: process.env.VUE_APP_DATE_FORMAT,
    testsUrl: process.env.VUE_APP_TESTS_URL,
};