import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { boot } from '@/boot';

Vue.config.productionTip = false;


new Vue({
    router,
    store,
    vuetify,
    computed: {
        darkTheme: {
            get(): boolean {
                return localStorage.getItem('dark-theme') === 'true';
            },
            set(value: boolean): void {
                localStorage.setItem('dark-theme', value.toString());
                this.$vuetify.theme.dark = value;
            }
        }
    },
    render: h => h(App),
    beforeCreate(): void {
        boot();
    }
}).$mount('#app');