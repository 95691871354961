<template>
    <nav>
        <v-navigation-drawer permanent mini-variant expand-on-hover app>
            <v-subheader>
                <v-icon>mdi-menu</v-icon>
            </v-subheader>
            <v-divider></v-divider>
            <v-list>
                <v-list-item-group color="amber accent-4">
                    <v-list-item v-for="{ text, route, icon } in links" :key="text" router :to="route">
                        <v-list-item-icon>
                            <v-icon>{{ icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="grey--text">
                            <v-list-item-title>{{ text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar id="app-bar" height="49" app fixed flat>
            <v-toolbar-title class="text-uppercase grey--text">
                <span class="font-weight-light">Gamzix</span>
                <span class="ml-1 mr-1 font-weight-light">|</span>
                <span class="text-subtitle-2">AT.</span>
            </v-toolbar-title>
            <v-spacer />
            <Logout />
        </v-app-bar>
    </nav>
</template>

<script>
import ErrorDialog from '@/components/ErrorDialog';
import Logout from '@/components/buttons/Logout';
export default {
    components: { Logout, ErrorDialog },
    data() {
        return {
            links: require('@/components/navbar/pages.json')
        };
    }
};
</script>

<style>
#app-bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
