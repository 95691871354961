var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('Navbar'), _c('v-main', {
    staticClass: "mx-3",
    attrs: {
      "app": ""
    }
  }, [_c('router-view', {
    staticClass: "mb-4"
  }), _c('ErrorDialog')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }