<template>
    <v-app>
        <Navbar />
        <v-main class="mx-3" app>
            <router-view class="mb-4" />
            <ErrorDialog />
        </v-main>
    </v-app>
</template>

<script>
import Navbar from '@/components/navbar/navbar.vue';
import ErrorDialog from '@/components/ErrorDialog';

export default {
    name: 'main-layout',
    components: { ErrorDialog, Navbar }
};
</script>
