var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', [_c('v-navigation-drawer', {
    attrs: {
      "permanent": "",
      "mini-variant": "",
      "expand-on-hover": "",
      "app": ""
    }
  }, [_c('v-subheader', [_c('v-icon', [_vm._v("mdi-menu")])], 1), _c('v-divider'), _c('v-list', [_c('v-list-item-group', {
    attrs: {
      "color": "amber accent-4"
    }
  }, _vm._l(_vm.links, function (_ref) {
    var text = _ref.text,
      route = _ref.route,
      icon = _ref.icon;
    return _c('v-list-item', {
      key: text,
      attrs: {
        "router": "",
        "to": route
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(icon))])], 1), _c('v-list-item-content', {
      staticClass: "grey--text"
    }, [_c('v-list-item-title', [_vm._v(_vm._s(text))])], 1)], 1);
  }), 1)], 1)], 1), _c('v-app-bar', {
    attrs: {
      "id": "app-bar",
      "height": "49",
      "app": "",
      "fixed": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "text-uppercase grey--text"
  }, [_c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("Gamzix")]), _c('span', {
    staticClass: "ml-1 mr-1 font-weight-light"
  }, [_vm._v("|")]), _c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("AT.")])]), _c('v-spacer'), _c('Logout')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }