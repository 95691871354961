import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import Api, { ApiInstance } from '@/utils/api';
import { ROUTE_NAMES, routes } from '@/router/routes';
import { env } from '@/configs';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext<Vue>): Promise<void> => {
    if (to.name !== ROUTE_NAMES.LOGIN && to.name !== ROUTE_NAMES.REG) {
        try {
            await Api.instance(ApiInstance.Autotests, {
                baseURL: env.apiUrl,
                timeout: 10000
            })?.get('auth/token-status');
        } catch (exception: unknown) {
            console.error(exception);
            next({ name: ROUTE_NAMES.LOGIN });
        }
    }
    next();
});

export default router;
