import Api, {ApiInstance} from '@/utils/api';

export const infoModule = {
  namespaced: true,
  state: () => ({
    games: [],
    casinos: [],
    currencies: [],
    integrationsParams: {},
    loaded: false,
  }),
  getters: {
    gameById: (state: any) => (gameId: number) => state.games.find((game: any) => game.id === gameId),
    gamesNameAndExternalId: (state: any) => state.games.map((game: any) => ({label: game.name, value: game.externalId})),
    loaded: (state: any) => state.loaded,
    getIntegrationParam: (state: any) => (api: string, scope: string) => {
      const levels = scope.split('.');
      let target: any = state.integrationsParams[api];
      let i = 0;
      for (const level of levels) {
        i++;
        if (typeof level === 'undefined' || typeof target === 'undefined' || typeof target[level] === 'undefined') {
          return ['rounds', 'games', 'id', 'playerId'].includes(level);

        }
        if (i === levels.length) {
          return target[level];
        } else {
          target = target[level];
        }
      }
    },
    apiHasParam: (state: any, getters: any) => (api: string, params: string) => {
      return !!getters.getIntegrationParam(api, params);
    }
  },
  mutations: {
    setGames(state: any, value: any): void {
      state.games = value;
    },
    setCasinos(state: any, value: any): void {
      state.casinos = value;
    },
    setCurrencies(state: any, value: any): void {
      state.currencies = value;
    },
    setLoaded(state: any, value: any): void {
      state.loaded = value;
    },
    setIntegrationsParams(state: any, value: any): void {
      state.integrationsParams = value;
    },
  },
  actions: {
    async getAll(store: any) {
      let games = await Api.instance(ApiInstance.Autotests).get('/info/games') as any[];
      games = games.sort((a, b) => a.externalId - b.externalId)
      const casinos = await Api.instance(ApiInstance.Autotests).get('/info/casinos');
      const currencies = await Api.instance(ApiInstance.Autotests).get('/info/currencies');
      const integrationsParams = await Api.instance(ApiInstance.Autotests).get('/info/integrationsParams');
      store.commit('setIntegrationsParams', integrationsParams);
      store.commit('setGames', games);
      store.commit('setCasinos', casinos);
      store.commit('setCurrencies', currencies);
      store.commit('setLoaded', true);
    }
  }
};
